import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react';
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const LoadingInline = () => {
	return <div style={{ width: "100%", textAlign: "center" }}>
		<FontAwesomeIcon icon={faSpinner} size="2x" spin />
	</div>;
};

export default LoadingInline;
