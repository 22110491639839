import { Link, Route, useParams } from 'react-router-dom';
import React, { useEffect, useState } from "react";
import { useUserContext, useUserContextUpdate } from '../../context/User/Provider';

import LoadingInline from "../../components/LoadingInline";
import Page from "././../Page";
import { TileContainer } from '../../common/styledElements';
import { TileInner } from "../Home";
import { menuGet } from "../../api/wp";

const Bar = () => {
    let { pageId } = useParams();
    // STATE
    const [primary, setPrimary] = useState()
    // CONTEXT
    const { drawerPages } = useUserContext()
    const { drawerPagesUpdate, showBottomBarUpdate } = useUserContextUpdate()

    useEffect(() => {
        showBottomBarUpdate(false)
    }, [])

    useEffect(() => {
        (async () => {
            const lang = localStorage.getItem('lang')
            const menuElems = await menuGet({ lang, side: "bar" })
            console.log("MENU ELEMS", menuElems)
            const menu = menuElems.results.slice(1).map(e => {
                return {
                    url: `/bar/${e.object_id}`,
                    title: e.title,
                    image: e.thumbnail
                }
            })
            console.log("BAR MENU", menu);
            drawerPagesUpdate(menu);
            setPrimary(Number(menuElems.results[0].object_id))
        })()
    }, [])

    return <>
        <Route exact path="/bar">

            {!primary && <div className="container mt-5">
                <div className="row">
                    <div className="col-12">
                        <LoadingInline />
                    </div>
                </div>
            </div>}
            {primary && <>
                <Page onDemand staticId={primary}>
                    {drawerPages &&
                        <div className="row justify-content-center">
                            {drawerPages.map(p => <div className="col-12">
                                <Link to={p.url} style={{ textDecoration: "none" }}>
                                    <TileContainer img={p.image}>
                                        {/* <TileInner className="black"> */}
                                        <TileInner>
                                            <h2 dangerouslySetInnerHTML={{ __html: p.title }}></h2>
                                        </TileInner>
                                    </TileContainer>
                                </Link>
                            </div>)}
                        </div>}
                </Page>
            </>}

        </Route>
        <Route path="/bar/:pageId">
            <Page onDemand />
        </Route>

    </>
}

export default Bar
