import { useEffect, useState } from "react"

import AES from "crypto-js/aes"
import UTF8 from "crypto-js/enc-utf8"
import { authGet } from "../../api/wp"
import { useHistory } from "react-router-dom"

const AES_seed = "J9FEsNSB%KNx%6Vy6kDj&QaPgn!YCp$ie&J^HuXn";

export const useAuth = () => {
    const history = useHistory()
    // STATE
    const [authObj, authObjSet] = useState()
    const [check, checkSet] = useState()
    const [authenticated, authenticatedSet] = useState(false)

    // FUNCTIONS
    const checkRefresh = () => checkSet(new Date())
    const getAuthObject = () => {
        console.log("GETTING AUTH OBJECT FROM WORDPRESS");
        (async () => {
            const aowp = await authGet()
            authSave(aowp.results)
            checkRefresh()
        })()
    }

    // GET AUTH FROM WP
    useEffect(() => {
        const ao = authRetrieve()
        if (!ao) getAuthObject()
        else {
            authObjSet(JSON.parse(ao))
            authenticatedSet(JSON.parse(ao).authenticated)
        }

    }, [check])

    useEffect(() => {
        if (!authObj) return

        if (history.location.pathname !== "/login")
            if (!authObj?.authenticated) history.push({
                pathname: "/login",
                state: {
                    from: history.location.pathname
                }
            })

    }, [authObj])

    // AUTHENTICATED -> SET SESSION DURATION AND SAVE AUTH TO LOCAL STORAGE
    useEffect(() => {
        if (!authenticated) return

        const currentMilliseconds = new Date().getTime()




        if (authObj.expire) {
            console.log("current", new Date(currentMilliseconds));
            console.log("expire", new Date(authObj.expire));
            
            if (currentMilliseconds > authObj.expire) {
                console.log("SESSION EXPIRED");
                authDestroy()
                checkRefresh()
                return
            }
            return
        }

        const duration = authObj.duration
        const durationMilliseconds = Number(duration) * 60 * 1000
        const expireMilliseconds = currentMilliseconds + durationMilliseconds;
        console.log("duration", duration);
        console.log("currentMilliseconds", currentMilliseconds, new Date(currentMilliseconds));
        console.log("expireMilliseconds", expireMilliseconds, new Date(expireMilliseconds));


        const ao = { ...authObj, authenticated: true, expire: expireMilliseconds };
        authSave(ao)
        checkRefresh()

        console.log("authenticated Effect", authObj);
    }, [authenticated])

    const AuthenticatedRender = ({ children }) => {
        return <>
            {authenticated ? <>{children}</> : <>Redirecting you to login</>}
        </>
    }


    return {
        ...authObj,
        authenticated,
        authenticatedSet,
        AuthenticatedRender
    }
}



const authSave = (auth) => {
    const encrAuth = AES.encrypt(JSON.stringify(auth), AES_seed).toString()
    localStorage.setItem("sodade_auth", encrAuth)
}

const authRetrieve = () => {
    const encrAo = localStorage.getItem("sodade_auth") // { pass: Number, expire: Number, authenticated: boolean }
    if (!encrAo) return false
    const ao = AES.decrypt(encrAo, AES_seed).toString(UTF8)
    return ao
}

const authDestroy = () => {
    localStorage.removeItem("sodade_auth")
}

window.authDestroy = authDestroy