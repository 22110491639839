import czFlag from "../assets/img/flags/cz.svg"
import deFlag from "../assets/img/flags/de.svg"
import frFlag from "../assets/img/flags/fr.svg"
import itFlag from "../assets/img/flags/it.svg"
import ptFlag from "../assets/img/flags/pt.svg"
import ukFlag from "../assets/img/flags/uk.svg"

const langs = [
	{
		lang: "it",
		flag: itFlag
	},
	{
		lang: "en",
		flag: ukFlag
	},
	{
		lang: "de",
		flag: deFlag
	},
	{
		lang: "fr",
		flag: frFlag
	},
	{
		lang: "pt",
		flag: ptFlag
	},
	{
		lang: "cs",
		flag: czFlag
	},
]

export default langs