import 'react-h5-audio-player/lib/styles.css';
import './style.scss';

import { AppBar, Box, Button, Fab, Grid, Toolbar } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom'
import { useUserContext, useUserContextUpdate } from '../../context/User/Provider';

import AudioPlayer from 'react-h5-audio-player';
import CloseIcon from '@material-ui/icons/Close';
import DialpadIcon from '@material-ui/icons/Dialpad';
import DoneIcon from '@material-ui/icons/Done';
import Numpad from "../Numpad"
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import styled from "styled-components";

const useStyles = makeStyles((theme) => ({

	appBar: {
		top: 'auto',
		bottom: 0,
	},
	grow: {
		flexGrow: 2,
	},
	fabButton: {
		position: 'absolute',
		zIndex: 1,
		top: -30,
		left: 0,
		right: 0,
		margin: '0 auto',
	},
}));


const BottomBar = () => {
	let history = useHistory()
	const { audio, numpad, numpadNumber, currentPage, showBottomBar } = useUserContext();
	const { audioUpdate, numpadUpdate, numpadNumberUpdate, changePageUpdate } = useUserContextUpdate();
	const classes = useStyles();

	function gotoNumber() {
		if (!numpadNumber) return
		changePageUpdate(true)
		numpadUpdate(false)
		audioUpdate(false)
		numpadNumberUpdate("")
		history.push(`/guida/points-of-interest/${numpadNumber}`);
	}

	return (
		<>
			{showBottomBar && (
				<>
					<AppBar position="fixed" color="secondary" className={classes.appBar}>
						<Toolbar>

							{!numpad ? (
								<Fab color="secondary" aria-label="add" className={classes.fabButton} onClick={() => numpadUpdate(true)}>
									<DialpadIcon />
								</Fab>
							) : !numpadNumber || numpadNumber === "" ? (
								<Fab color="secondary" aria-label="add" className={classes.fabButton} onClick={() => numpadUpdate(false)}>
									<CloseIcon />
								</Fab>
							) : (
								<Fab color="secondary" aria-label="add" className={classes.fabButton} onClick={gotoNumber}>
									<DoneIcon />
								</Fab>
							)}


							{audio && <AudioPlayerStyled
								autoPlay
								src={audio}
								onPlay={e => console.log("onPlay")}
								showJumpControls={false}
								customAdditionalControls={[]}
								customVolumeControls={[]}
							/>}
						</Toolbar>
					</AppBar>

					{numpad && (
						<SecondaryBar position="fixed" color="secondary" className={classes.appBar} page={currentPage}>
							<Toolbar>
								<Numpad />
							</Toolbar>
						</SecondaryBar>
					)}
				</>
			)}
		</>
	);
};

const SecondaryBar = styled(AppBar)`
	padding: 2rem 3rem;
	padding-bottom: ${(props) => props.page === "poiSingle" ? "11rem" : "7rem"};
	z-index: 1;
`

const AudioPlayerStyled = styled(AudioPlayer)`
	box-shadow: none;
	margin-top: 2rem;
	outline: none !important;
`

export { BottomBar };
