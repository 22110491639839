import styled from 'styled-components';
import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useIndexedDB } from 'react-indexed-db';

export const ClearData = () => {

    const history = useHistory()

    const DB_offlineData = useIndexedDB('offlineData');
    const DB_offlinePOI = useIndexedDB('offlinePOI');
    const DB_offlinePages = useIndexedDB('offlinePages');
    const DB_offlineMenu = useIndexedDB('offlineMenu');

    useEffect(() => {
        (async () => {
            try {
                localStorage.clear()
                await DB_offlineData.clear()
                await DB_offlinePOI.clear()
                await DB_offlinePages.clear()
                await DB_offlineMenu.clear()

                history.push("/")
            } catch(e) {
                console.log("Error", e);
                history.push("/")
            }
        })();
    }, [])

    return <Container>
        Cleaning data, please wait...
    </Container>
}

const Container = styled.div`
    width: 100%;
    height: 95vh;
    display: flex;
    justify-content: center;
    align-items: center;
`