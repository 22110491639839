import React, { useEffect, useState } from 'react';

import { Chip } from '@material-ui/core';
import {TileContainer} from "../../common/styledElements"
import styled from "styled-components";
import { useIndexedDB } from 'react-indexed-db';
import { useUserContext } from '../../context/User/Provider';

const Tile = ({ point, classes }) => {
    // STATE
    const [featuredImage, setFeaturedImage] = useState();
    // CONTEXT
    const { points } = useUserContext();
    // IDB
    const { getByID } = useIndexedDB('offlineData');


    // useEffect(() => {
    // 	if (featuredImage) return
    // 	let p = points?.find((p) => p.code == code);
    // 	if (!p?.featuredImage) return;
    // getByID(p?.featuredImage).then((dataFromDB) => {
    // 	console.log(dataFromDB);
    // 	setFeaturedImage(dataFromDB);
    // 	setPoint(p)
    // });
    // }, [featuredImage]);

    function featuredImageGet() {
        getByID(point.featuredImage).then((dataFromDB) => {
            if (!dataFromDB) return
            if (!dataFromDB.data) return
            const mediaBlob = new Blob([dataFromDB.data]);
            const mediaObjectURL = window.URL.createObjectURL(mediaBlob);
            // let url = URL.createObjectURL(dataFromDB.data);
            // setFeaturedImage(url);
            setFeaturedImage(mediaObjectURL);
        });
    }


    useEffect(() => {
        window.fig = featuredImageGet

        if (featuredImage) return

        featuredImageGet()

    }, [featuredImage])



    // console.log(points?.find((p) => p.code == code));
    return point && (
        <>
            <TileContainer img={featuredImage}>
                <div className="inner">
                    <div className="row">
                        <div className="col-auto d-flex align-items-center">
                            <ChipStyled>{point.code}</ChipStyled>
                        </div>
                        <div className="col">
                            <div className="name" dangerouslySetInnerHTML={{ __html: point.name}}></div>
                        </div>
                    </div>
                </div>
            </TileContainer>
        </>
    );
};

export default Tile

const ChipStyled = styled.div`
    font-size: 1.2rem;
    line-height: 2rem;
    text-align: center;
    vertical-align: middle;
    width: 2rem;
    height: 2rem;
    background-color: #eee;
    border-radius: 50%;
    color: #000;
`
