import styled from "styled-components";

const TileContainer = styled.div`
    margin: 0.25rem 0;
    background-image: url(${props => props.img});
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    .inner {
        display: flex;
        width: 100%;
        align-items: center;
        min-height: 15rem;
        /* padding: 3rem 2rem; */
        padding: 0 2rem;
        background-color: rgba(0,0,0,0.5);

        .name {
            color: #fff;
            font-size: 1.2rem;
        }
    }
`


export {
    TileContainer
}