import { AppBar, Box, Button, Fab, Grid, Toolbar } from '@material-ui/core';
import React, { useState } from 'react'
import { useUserContext, useUserContextUpdate } from '../context/User/Provider';

import BackspaceIcon from '@material-ui/icons/Backspace';
import styled from "styled-components"

const Numpad = () => {
    //// STATE
    const [number, setNumber] = useState("")
    //// CONTEXT
    const { numpadNumberUpdate } = useUserContextUpdate();

    function numberAdd(n) {
        const nTemp = number.concat(n)
        setNumber(nTemp)
        numpadNumberUpdate(nTemp)
    }
    function numberRemove() {
        const nTemp = number.slice(0, -1)
        setNumber(nTemp)
        numpadNumberUpdate(nTemp)
    }

    return (
        <>
            <Grid container justify="center">
                <Grid item xs={12}>
                    <Box textAlign="center">
                        <NumberContainer>
                            {number}
                        </NumberContainer>
                    </Box>
                </Grid>
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, "", 0, { i: BackspaceIcon, fn: numberRemove }].map((n, k) => (
                    <Grid item xs={4} key={k}>
                        <Box textAlign="center">
                            {typeof n === "number" && (
                                <NumBtn className={`btn-${n}`} onClick={() => numberAdd(n)}>
                                    {n}
                                </NumBtn>
                            )}
                            {typeof n === "object" && (
                                <NumBtn  onClick={n.fn}>
                                    <n.i />
                                </NumBtn>
                            )}
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </>
    )
}

const NumBtn = styled.button`
    padding: 0.7rem 1rem;
    width: 100%;
    background-color: #fff;
    color: #000;
    border: 0px;

    /* &.btn-1, &.btn-4, &.btn-7 {
        border-right: 1px solid;
    }
    &.btn-3, &.btn-6, &.btn-9 {
        border-left: 1px solid;
    } */
    &.btn-1, &.btn-2, &.btn-3,
    &.btn-4, &.btn-5, &.btn-6,
    &.btn-7, &.btn-8, &.btn-9 {
        border-bottom: 1px solid #555;
    }
    &.btn-0, &.btn-2,&.btn-5,&.btn-8 {
        border-left: 1px solid #555;
        border-right: 1px solid #555;
    }
`

const NumberContainer = styled.div`
    display: block;
    height: 2rem;
    background-color: #eee;
    border-radius: 3px;
    margin: 0 15px;
    line-height: 2rem;
    margin-bottom: 2rem;
`

export default Numpad