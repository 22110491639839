import React, { useState } from 'react';

import { BroadcastChannel } from "broadcast-channel";
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import W from "../locales";

const Notifications = () => {
    //// STATE
    const [updates, setUpdates] = useState(false)
    const [open, setOpen] = useState(false);

    const channel = new BroadcastChannel('service-worker-channel')
    // channel.onmessage = msg => console.dir(msg);
    // channel.onmessage = (message) => {
    //     if (message?.data?.promptToReload) {
    //         setUpdates(true)
    //         setOpen(true)
    //     }
    // }
    // const handler = msg => console.log(msg);
    // channel.addEventListener('message', handler);
    // channel.onmessage = msg => console.dir(msg);

    channel.onmessage = msg => {
        console.log("notifications broadcast msg", msg);
        if (msg.updateNotification) {
            channel.postMessage({ skipWaiting: true })
            setOpen(true)
        }
    }

    function updateDo() {
        channel.postMessage({ skipWaiting: true })
        setOpen(false)
    }



    return (
        <> 
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={open}
                // onClose={() => setOpen(false)}
                message={(<W i="noti_updateMsg" />)}
                action={
                    <React.Fragment>
                        <Button color="secondary" size="small" onClick={updateDo}>
                            <W i="noti_updateBtn" />
                        </Button>
                        {/* <IconButton size="small" aria-label="close" color="inherit" onClick={() => setOpen(false)}>
                            <CloseIcon fontSize="small" />
                        </IconButton> */}
                    </React.Fragment>
                }
            />
        </>
    )
}

export default Notifications
