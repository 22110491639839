import React from 'react'

const credits = () => {
    return (
        <div className="mt-5">
            <div className="container">
                <div className="row">
                    <div className="col-12 text-center">
                        <h1>Sodade</h1>
                        <h3>Casa da cultura</h3>
                    </div>
                    <div className="col-12">
                        Icons made by <a href="https://www.flaticon.com/authors/freepik" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default credits