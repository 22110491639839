import React, { useEffect, useState } from 'react'
import { useUserContext, useUserContextUpdate } from '../context/User/Provider';

import AssetsDownload from "../pages/AssetsDownload"
import { Redirect } from "react-router-dom"

const RedirectChecks = () => {
    const [langTemp, setLangTemp] = useState();
    const [langChecked, setLangChecked] = useState(false);
    const { assetsDownloaded, lang } = useUserContext();
    const { langUpdate } = useUserContextUpdate()



    // useEffect(() => {
    //     // let isMounted = true; // note this flag denote mount status
    //     // localStorage.getItem('lang').then(data => {
    //     //     if (isMounted) langUpdate(localStorage.getItem('lang'))
    //     // })
    //     // return () => { isMounted = false }; // use effect cleanup to set flag false, if unmounted
    //     let localLang = localStorage.getItem('lang') ? localStorage.getItem('lang') : false

    //     console.log('localLang', localLang)
    //     setLangTemp(localLang)
    //     console.log('langTemp', langTemp)
    // }, [setLangTemp]);

    useEffect(() => {
        let localLang = localStorage.getItem('lang') ? localStorage.getItem('lang') : false
        setLangChecked(true)
        if (!localLang) return
        console.log("Local lang check");
        langUpdate(localLang)
    }, [langTemp, langUpdate])


    if (langChecked && !lang) return <Redirect to="/lang-update" />
    if (!assetsDownloaded) return <AssetsDownload fixed={true} />
    return null
    // return (
    //     <>
    //         {!lang ? <Redirect to="/lang-update" /> : !assetsDownloaded && <AssetsDownload fixed={true} />}
    //         {/* {!assetsDownloaded && <Redirect to="/assets-download" />} */}
    //         {/* {!assetsDownloaded && <AssetsDownload fixed={true} />} */}
    //     </>
    // )
}

export default RedirectChecks
