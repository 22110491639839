import LanguageIcon from '@material-ui/icons/Language';
import langs from "../common/langs";
import styled from "styled-components"
import { useUserContext } from "../context/User/Provider";

export default function LangIcon() {
    const lang = localStorage.getItem('lang');

    return <>
        {lang && <Flag src={langs.filter(l => l.lang === lang)[0].flag} />}
        {!lang && <LanguageIcon />}
    </>
}


const Flag = styled.img`
    width: 1.5rem;
    height: 1.5rem;
`