import { Button, TextField } from '@material-ui/core';
import { useEffect, useState } from "react"

import styled from "styled-components"
import { useAuth } from "../components/Auth"
import { useHistory } from "react-router-dom"

const Login = () => {

    const [value, setValue] = useState()
    const [authResult, authResultSet] = useState()
    const history = useHistory()

    const { pass, authenticated, authenticatedSet } = useAuth()

    useEffect(() => {
        if (!authenticated) return
        // console.log("AUTH", authenticated);
        // console.log(history);
        const returnTo = history?.location?.state?.from ? history.location.state.from : "/home"
        // console.log();
        history.push(returnTo)
    }, [authenticated])



    const handleChange = (event) => {
        setValue(event.target.value);
    }

    const handleSubmit = (event) => {
        // alert('E\' stato inserito un nome: ' + value);
        const pInput = event.target.querySelector("#passwd").value;
        // console.log("pass", pass);
        if (String(pInput) === String(pass)) {
            authResultSet(true)
            authenticatedSet(true)
        }
        event.preventDefault();
    }


    return <div>
        <br />



        <div className="container">
            <div className="row">
                <div className="col-12 text-center">
                    <h1>Login</h1>
                </div>
            </div>
            <div className="row justify-content-center mt-5">
                <div className="col-auto">
                    <form onSubmit={handleSubmit}>
                        <TextField id="passwd" type="password" />
                        <Button type="submit" variant="contained" color="primary">
                            Login
                        </Button>
                    </form>
                </div>
            </div>
        </div>
    </div>

}


export default Login



const StyledForm = styled.form`
    
`