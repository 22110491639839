import { List, ListItem, Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom'

import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { Link } from 'react-router-dom';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import W from "../locales";
import styled from "styled-components";

export default function DrawerList({ page, elements, classes, primary, closeFn, ...others }) {
    const [open, setOpen] = useState(false);
    const [isCurrentPath, setIsCurrentPath] = useState(false);
    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        console.log("PRIMARY", primary);
        console.log("page", page);
        console.log("location", location);
        console.log(primary.url.split("/")[1]);
        console.log(location.pathname.split("/")[1]);

        if (primary.url.split("/")[1] === location.pathname.split("/")[1]) {
            setIsCurrentPath(true);
            setOpen(true);
        }
    }, [page])

    const handleClick = () => {
        if (isCurrentPath) {
            setOpen(!open);
        } else {
            history.push(`/${primary.url.split("/")[1]}`);
            closeFn();
        }
    };

    return <Elem {...others}>
        <ListItem button onClick={handleClick}>
            <ListItemText>
                <Typography type="body2" className={classes.listTypo}>
                    <W i={`page_${page}`} />
                </Typography>
            </ListItemText>
            {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
            <div onClick={closeFn}>
                <List component="div" disablePadding>
                    {elements.map((page, k) => <ListItem button className={classes.nested} key={k} component={StyledLink} to={page.url}>
                        <ListItemIcon>
                            <ChevronRightIcon />
                        </ListItemIcon>
                        <ListItemText component={StyledLink} to={page.url}>
                            <Typography type="body2" className={classes.listTypo} dangerouslySetInnerHTML={{ __html: page.title }}>
                                {/* <W i="page_casa" /> */}
                                {/* {page.title} */}
                            </Typography>
                        </ListItemText>
                    </ListItem>)}
                </List>
            </div>
        </Collapse>
    </Elem>
}

const Elem = styled.div``
const StyledLink = styled(Link)`
    text-decoration: none !important;
`