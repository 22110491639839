import { Link, Route, BrowserRouter as Router, Switch, useParams, useRouteMatch } from 'react-router-dom';
import { LoadPointsContext, PointFull, PointTease } from '../components/PointOfInterest/';
import React, { useEffect, useState } from 'react';
import { useUserContext, useUserContextUpdate } from '../context/User/Provider';

import Grid from '@material-ui/core/Grid';
import GridListTile from '@material-ui/core/GridListTile';
import Paper from '@material-ui/core/Paper';
import Test from './Test';
import Tile from "../components/PointOfInterest/tile"
import { makeStyles } from '@material-ui/core/styles';
import styled from "styled-components"
import theme from "../theme"

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-around',
		overflow: 'hidden',
		backgroundColor: theme.palette.background.paper,
	},
	gridList: {
		width: "100%",
		height: 450,
	},
	icon: {
		color: 'rgba(255, 255, 255, 0.54)',
	},
}));

const PointsOfInterest = () => {
	let match = useRouteMatch();
	const { points, currentPage } = useUserContext();
	const { currentPageUpdate, showBottomBarUpdate } = useUserContextUpdate();
	const classes = useStyles();

	useEffect(()=> {
		showBottomBarUpdate(true)
	},[])

	return (
		<>
			{/* <LoadPointsContext /> */}

			<Switch>
				<Route path={`${match.path}/:code`} children={<PointFull points />} />
				<Route path={match.path} children={<PointsList points />} />
			</Switch>
		</>
	);
};

export default PointsOfInterest;



const PointsList = () => {
	let match = useRouteMatch();
	const { points, currentPage } = useUserContext();
	const { currentPageUpdate, audioUpdate } = useUserContextUpdate();
	//// STATE
	const [pointsSorted, setPointsSorted] = useState(false)

	// console.group("points list")
	// console.log('points', points)
	// console.groupEnd()


	useEffect(() => {
		if (points && currentPage !== "poiList") currentPageUpdate("poiList");
		audioUpdate(false);
	}, [currentPage])


	useEffect(() => {
		if (pointsSorted) return
		if (points) {
			points.sort(function (x, y) {
				return x.code - y.code;
			});
			setPointsSorted(true)
			// console.table(points)
		}
	}, [points, pointsSorted])

	return (
		<>
			<Grid container>
				{points && pointsSorted &&
					points.map((p, k) => {
						if (!p.hide) return (
							<Grid item xs={12} key={k}>
								<StyledLink to={`${match.url}/${p.code}`}>
									<Tile point={p} />
								</StyledLink>
							</Grid>
						);
					})}

			</Grid>
		</>
	)
}

const StyledLink = styled(Link)`
	text-decoration: none !important;
`