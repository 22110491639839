import 'react-circular-progressbar/dist/styles.css';

import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import React, { useEffect, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { menuGet, pagesGet, poiGet } from '../api/wp';
import { useUserContext, useUserContextUpdate } from '../context/User/Provider';

import W from '../locales'
import axios from 'axios';
import logoSrc from "../assets/img/logo-sodade.png"
import styled from 'styled-components';
import { useIndexedDB } from 'react-indexed-db';

const AssetsDownloadPage = ({ fixed }) => {
	// STATE
	const [poi, setPoi] = useState(false);
	const [pages, setPages] = useState(false);
	const [menu, setMenu] = useState(false);


	const [offlineData, setOfflineData] = useState(false);
	const [offlinePOI, setOfflinePOI] = useState(false);
	const [offlinePages, setOfflinePages] = useState(false);
	const [fetchDone, setFetchDone] = useState(false)


	// eslint-disable-next-line no-unused-vars
	const [dataDownloadInit, setDataDownloadInit] = useState(false);
	const [dataDownloading, setDataDownloading] = useState(false)
	const [dataDownloaded, setDataDownloaded] = useState(false);
	const [dlPercTotal, setDlPercTotal] = useState(0);
	const [dlPercCurrent, setDlPercCurrent] = useState(0);
	// CONTEXT
	const { assetsDownloaded, lang } = useUserContext();
	const { assetsDownloadedUpdate, pointsUpdate, pagesUpdate, dataUpdate } = useUserContextUpdate();
	// IDB
	const DB_offlineData = useIndexedDB('offlineData');
	const DB_offlinePOI = useIndexedDB('offlinePOI');
	const DB_offlinePages = useIndexedDB('offlinePages');
	const DB_offlineMenu = useIndexedDB('offlineMenu');

	let history = useHistory()

	// 01 POI FETCH
	async function downloadStart() {



	}

	useEffect(() => {
		if (fetchDone) return
		(async () => {
			let poiResp = await poiGet({ lang: localStorage.getItem('lang') });
			let pagesResp = await pagesGet({ lang: localStorage.getItem('lang') });
			// let menuResp = await menuGet({ lang: localStorage.getItem('lang'), side: "casa" })
			console.log("PAGES RESP", pagesResp.results);
			// setDataDownloadInit(true);
			await setPoi(poiResp.results);
			await setPages(pagesResp.results);
			// await setMenu(menuResp.results);

			setFetchDone(true)
		})()
	}, [fetchDone])

	// 02 POI LOADED => POI REMAP
	useEffect(() => {
		if (!fetchDone) return
		(async () => {

			console.log("poi", poi);
			console.log("pages", pages);

			if (dataDownloaded) return;
			if (!poi) return;
			console.log('poi loaded', poi);
			// setDataDownloadInit(false);

			let tempOfflineData = [];
			let tempOfflinePOI = [];
			let tempOfflinePages = [];



			poi.map((p) => {
				if (tempOfflineData.find((x) => x.id === p.fields.infopoint_audio_track.ID)) return p;

				// Audio track
				if (p.fields.infopoint_audio_track.url)
					if (!tempOfflineData.find((d) => d.id === p.fields.infopoint_audio_track.ID))
						tempOfflineData.push({
							id: p.fields.infopoint_audio_track.ID,
							type: 'audio',
							url: p.fields.infopoint_audio_track.url,
							downloaded: false,
						});

				// Gallery images
				if (p.fields.infopoint_gallery.length)
					p.fields.infopoint_gallery.map((g) => {
						if (!tempOfflineData.find((d) => d.id === g.ID))
							tempOfflineData.push({
								id: g.ID,
								type: 'image',
								url: g.url,
								caption: g.caption,
								downloaded: false,
							});
						return g
					});

				// Featured image
				if (p.featured_image && p?.featured_image?.id !== 0)
					if (!tempOfflineData.find((d) => d.id === p?.featured_image?.id))
						tempOfflineData.push({
							id: p.featured_image.id,
							type: 'image',
							url: p.featured_image.url,
							downloaded: false,
						});

				// Points of interest
				tempOfflinePOI.push({
					code: parseInt(p.fields.infopoint_cod),
					hide: p?.fields?.nascondi,
					name: p.name,
					content: p.content,
					// TODO
					featuredImage: p?.featured_image?.id,
					gallery:
						p?.fields?.infopoint_gallery &&
						p.fields.infopoint_gallery.map((g) => {
							return {
								id: g.id,
							};
						}),
					audio: p?.fields?.infopoint_audio_track?.ID,
					related: p?.related
				});

				// console.log('tempOfflinePOI', tempOfflinePOI);

				return p;
			});

			pages.map((p) => {

				// Featured image
				if (p.featured_image && p?.featured_image?.id !== 0)
					if (!tempOfflineData.find((d) => d.id === p?.featured_image?.id))
						tempOfflineData.push({
							id: p.featured_image.id,
							type: 'image',
							url: p.featured_image.url,
							downloaded: false,
						});

				// Page
				tempOfflinePages.push({
					id: parseInt(p.id),
					slug: p.slug,
					name: p.name,
					content: p.content,
					featuredImage: p?.featured_image?.id
				});

				console.log('tempOfflinePages', tempOfflinePages);

				return p;
			});


			// console.log('tempOfflineData', tempOfflineData);
			setOfflineData(tempOfflineData);
			setOfflinePOI(tempOfflinePOI);
			setOfflinePages(tempOfflinePages);
			setDataDownloadInit(true)


		})()
	}, [fetchDone]);

	//* 03 IDB CHECK => DOWNLOAD
	useEffect(() => {
		console.log("UE 03", dataDownloadInit, dataDownloaded);
		if (!dataDownloadInit) return
		if (dataDownloaded) return
		// if (dataDownloadInit && dataDownloaded) {
		// 	setDataDownloaded(true);
		// 	assetsDownloadedUpdate(true);
		// 	return
		// }
		if (!offlineData || !offlinePOI || !offlinePages) return;
		(async () => {
			// await setDlPercTotal(offlineData.length + offlinePages.length + offlinePOI.length + 1); // 1 = menu download
			await setDlPercTotal(offlineData.length + offlinePages.length + offlinePOI.length);

			console.log('offlineData loaded', offlineData);
			console.log('offlinePOI loaded', offlinePOI);
			console.log('offlinePages loaded', offlinePages);
			console.log(`total lenght: ${dlPercTotal}`);

			let promises = [
				// Download OfflinePOI
				Array.from(offlinePOI, (elem) => {
					return new Promise((resolve) => {
						DB_offlinePOI.getByID(elem.code).then((o) => {
							if (o) {
								console.log('POI alredy exist', o);
								setDlPercCurrent((c) => c + 1);
								resolve();
							} else {
								DB_offlinePOI.add({ id: elem.code, ...elem }).then(
									(event) => {
										// await dlPercIncrement();
										setDlPercCurrent((c) => c + 1);
										resolve();
										console.log('ID Generated: [POI] ', event);
									},
									(error) => {
										console.error("ERROR", error);
									}
								);
							}
						});
					});
				}),

				// Download OfflinePages
				Array.from(offlinePages, (elem) => {
					return new Promise((resolve) => {
						DB_offlinePages.getByID(elem.id).then((o) => {
							console.log('O', o);
							if (o) {
								if (elem.content !== o.content) {
									DB_offlinePages.update({ id: elem.id, ...elem }).then(
										(event) => {
											// await dlPercIncrement();
											setDlPercCurrent((c) => c + 1);
											resolve();
											console.log('ID Generated: [PAGE] ', event);
										},
										(error) => {
											console.error("ERROR", error);
										}
									);
								} else {
									setDlPercCurrent((c) => c + 1);
									resolve();
								}
							} else {
								DB_offlinePages.add({ id: elem.id, ...elem }).then(
									async (event) => {
										// await dlPercIncrement();
										setDlPercCurrent((c) => c + 1);
										resolve();
										console.log('ID Generated: [PAGE] ', event);
									},
									(error) => {
										console.error("ERROR", error);
									}
								);
							}
						});
					});
				}),


				// Download OfflineData
				Array.from(offlineData, (elem) => {
					return new Promise((resolve) => {
						DB_offlineData.getByID(elem.id).then(async (o) => {
							if (o) {
								console.log('alredy exist', o);
								setDlPercCurrent((c) => c + 1);
								resolve();
							} else {
								// setDataDownloading(true) // TEST: MOVED BEFORE Promise.all()
								console.log("blob type", o, elem);
								// const blob = await axios({
								// 	url: elem.url,
								// 	method: 'GET',
								// 	responseType: 'blob', // important
								// 	mode: 'no-cors',
								// }).then((response) => {
								// 	// if (elem.type === "audio") return new Blob([response.data], { type: 'audio/mpeg' })
								// 	return new Blob([response.data]);
								// 	// return new Promise((resolve, reject) => {
								// 	// 	const reader = new FileReader();
								// 	// 	reader.addEventListener("loadend", e => {
								// 	// 	  resolve(reader.result);
								// 	// 	});
								// 	// 	reader.addEventListener("error", reject);
								// 	// 	reader.readAsArrayBuffer(blob);
								// 	//   });
								// });

								// const response = await window.fetch(elem.url)
								// const audioArrayBuffer = await response.arrayBuffer();
								// const uuu = elem.type === "audio" ? 'https://upload.wikimedia.org/wikipedia/commons/transcoded/a/ab/Alexander_Graham_Bell%27s_Voice.ogg/Alexander_Graham_Bell%27s_Voice.ogg.mp3' : elem.url
								const uuu = elem.url
								const response = await window.fetch(uuu);


								const audioArrayBuffer = await response.arrayBuffer();
								const audioBlob = new Blob([audioArrayBuffer]);
								// console.log("MIME", audioBlob.type);

								console.log("file", response.url);
								console.log("audioArrayBuffer", audioArrayBuffer);

								DB_offlineData.add({ id: elem.id, type: elem.type, caption: elem.caption, data: audioArrayBuffer }).then(
									async (event) => {
										// await dlPercIncrement();
										setDlPercCurrent((c) => c + 1);
										resolve();
										console.log('ID Generated: [Data] ', event);
									},
									(error) => {
										console.error("ERROR", error);
									}
								);
							}
						});
					});
				}),

				// [
				// 	new Promise(resolve => {
				// 		// DB_offlineMenu.clear().then(()=> {
				// 		DB_offlineMenu.add({ id: "casa", elements: menu }).then(async (event) => {
				// 			setDlPercCurrent((c) => c + 1);
				// 			console.log("Menu elements saved", event);
				// 			resolve()
				// 		}).catch(e => {
				// 			console.log("promise catch", e);
				// 			DB_offlineMenu.clear().then(() => {
				// 				DB_offlineMenu.add({ id: "casa", elements: menu }).then(async (event) => {
				// 					setDlPercCurrent((c) => c + 1);
				// 					console.log("Menu elements saved", event);
				// 					resolve()
				// 				})
				// 			})
				// 		})
				// 	})
				// ]
			];


			setDataDownloading(true)
			Promise.all(promises.flat()).then((data) => {
				console.log("Dowload promises after");
				pointsUpdate(offlinePOI);
				pagesUpdate(offlinePages);
				dataUpdate(offlineData);
				setDataDownloaded(true);
				assetsDownloadedUpdate(true);
				// history.push("/casa/points-of-interest")
			}).then(() => {
			})
		})()

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dataDownloadInit]);

	return (
		<>
			{/* {assetsDownloaded && <Redirect to="/" />} */}



			<Wrapper fixed={fixed}>
				<div className="inner">
					<div className="container">
						<div className="row">
							{/* <div className="col-12">
								<Link to="/">Go home</Link>
							</div> */}
							{!dataDownloading && (
								<div className="col-12 text-center">
									<Logo src={logoSrc} />
									<h6>Loading...</h6>
								</div>
							)}

							{dataDownloading && (
								<div className="col-12">
									<div className="row justify-content-center">
										<div className="col-6">
											<CircularProgressbarWithChildren
												value={Math.round((dlPercCurrent / dlPercTotal) * 100)}
												// text={`${Math.round((dlPercCurrent / dlPercTotal) * 100)}%`}
												styles={buildStyles({
													pathTransitionDuration: 0.15,
													textSize: '14px',
													pathColor: "#55908b"
												})}
												strokeWidth={3}
											>
												<img style={{ width: 90 }} src={logoSrc} alt="logo" />
											</CircularProgressbarWithChildren>
											<h6 className="mt-3 text-center">
												<W i="loading" />
											</h6>
										</div>
										<div className="col-12 text-center mt-5">
											<W i="loading_description" />
										</div>
										{/* <div className="col-12 text-center mt-5">
											Assets: {dlPercCurrent}/{dlPercTotal}
										</div> */}
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			</Wrapper>


			<div>




				{/* <Debug
					downloadStart={downloadStart}
					dataDownloaded={dataDownloaded}
					offlineData={offlineData}
					poi={poi}
				/> */}
			</div>
		</>
	);
};

const Logo = styled.img`
	height: 8rem;
	width: auto;
	margin-bottom: 2rem;
`

const Wrapper = styled.div`
	top: 0;
	left: 0;
	width: 100%;
	border: 1px solid green;
	position: ${props => props.fixed ? 'fixed' : 'relative'};
	height: ${props => props.fixed ? '100%' : 'unset'};
	align-self: center;
	z-index: 2000;
	background-color: #fff;
	display: flex;
    justify-items: center;
	align-items: center;
	
	.inner {
		width: 100%;
	}
`



function ClearAll() {
	const { clear } = useIndexedDB('offlineData');

	const handleClick = () => {
		clear().then(() => {
			alert('All Clear!');
			window.location.reload();
		});
	};

	return <button onClick={handleClick}>Clear All</button>;
}


const Debug = ({ offlineData, poi, downloadStart, dataDownloaded }) => {
	return (
		<div>
			<div>
				Downloaded? {dataDownloaded ? 'true' : 'false'}
			</div>
			<div>
				<button onClick={async () => await downloadStart()}>Download</button>
				<ClearAll />
			</div>

			{poi && <div>{typeof poi}</div>}
			<div style={{ border: '1px solid' }}>
				<h3>POIs</h3>
				<ul>
					{poi &&
						poi.map((p, id) => {
							return <li key={`p-${id}`}>{p.name}</li>;
						})}
				</ul>
			</div>

			<div style={{ border: '1px solid' }}>
				<h3>Data</h3>
				<ul>
					{offlineData &&
						offlineData.map((d) => {
							return (
								<li key={`d-${d.id}`}>
									{d.id} [{d.type}] {d.url}
									{/* <Audio id={d.id} /> */}
									{/* <audio controls src={idbMediaToUrl(d.id)}></audio> */}
									{/* {idbMediaToUrl(d.id)} */}
								</li>
							);
						})}
				</ul>
			</div>
		</div>
	)
}


export default AssetsDownloadPage;