// import { createMuiTheme } from '@material-ui/core/styles';
import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core';
import green from '@material-ui/core/colors/green';
import purple from '@material-ui/core/colors/purple';

const theme = createMuiTheme({
    palette: {
        // primary: {
        //     main: "#55908b",
        // },
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#55908b',
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        },
        secondary: {
            main: '#ffffff',
            // dark: will be calculated from palette.secondary.main,
            contrastText: '#55908b',
        },

        text: {
            primary: "#55908b"
        }
    },
});

export default theme