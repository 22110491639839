import { Divider, Drawer, IconButton, List, ListItem, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useUserContext, useUserContextUpdate } from '../context/User/Provider';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import DrawerList from './DrawerList';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import LangIcon from './LangIcon';
import { Link } from 'react-router-dom';
import ListItemText from '@material-ui/core/ListItemText';
import LogoSrc from "../assets/img/logo-sodade.png";
import W from '../locales';
import { makeStyles } from '@material-ui/core/styles';
import packageJson from '../package.alias.json';
import styled from "styled-components";
import theme from '../theme';

const drawerWidth = 320;

const useStyles = makeStyles((theme) => ({

    palette: {
        text: {
            primary: "rgba(0, 0, 0, 0.54)"
        }
    },

    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        position: "relative",
        paddingBottom: "4rem"
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'space-between',
    },
    drawerFooter: {
        position: "fixed",
        backgroundColor: "#fff",
        width: drawerWidth,
        zIndex: 100,
        bottom: 0,
        textAlign: "center",
        paddingBottom: 10,
        borderTop: "1px solid rgba(0,0,0,0.2)",
    },
    listTypo: {
        color: "#333",
        textDecoration: "none"
    }

}));






export default function TemporaryDrawer() {
    const classes = useStyles();
    // CONTEXT
    const { drawer, drawerPages, drawerAllPages } = useUserContext()
    const { drawerUpdate } = useUserContextUpdate()
    // STATE
    const [open, setOpen] = useState(true);

    const handleClick = () => {
        setOpen(!open);
    };

    useEffect(() => {
        console.log("PAGES", drawerAllPages);
    }, [])

    return (
        <>
            <Drawer
                className={classes.drawer}
                variant="temporary"
                anchor="left"
                open={drawer ? true : false} onClose={() => drawerUpdate(false)}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <div className={classes.drawerHeader}>
                    <Link to="/lang-update" onClick={() => drawerUpdate(false)}>
                        <IconButton>
                            <LangIcon />
                        </IconButton>
                    </Link>
                    <IconButton onClick={() => drawerUpdate(false)}>
                        {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </div>

                <List>
                    <ListItem component={StyledLink} to="/home">
                        <DrawerLogo src={LogoSrc} />
                    </ListItem>

                </List>
                <Divider />
                {/* <div onClick={() => drawerUpdate(false)}>
                        <List>
                            <ListItem component={StyledLink} to="/bar">
                                <ListItemText>
                                    <Typography type="body2" className={classes.listTypo}>
                                        <W i="page_bar" />
                                    </Typography>
                                </ListItemText>
                            </ListItem>

                            <ListItem component={StyledLink} to="/casa">
                                <ListItemText>
                                    <Typography type="body2" className={classes.listTypo}>
                                        <W i="page_casa" />
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                        </List>
                        <Divider />
                        <List>

                            {drawerPages && drawerPages.map((dp, k) =>
                                <ListItem component={StyledLink} to={dp.url} key={k}>
                                    <ListItemText>
                                        <Typography type="body2" className={classes.listTypo} dangerouslySetInnerHTML={{ __html: dp.title }}>
                                        </Typography>
                                    </ListItemText>
                                </ListItem>)}


                        </List>
                        <Divider />

                        
                    </div> */}

                {!drawerAllPages ? <>
                    <div style={{ textAlign: "center" }}>
                        <LoadingIcon edge="start" className={classes.menuButton} color="inherit" aria-label="menu" style={{ minWidth: "36px" }}>
                            <HourglassEmptyIcon />
                        </LoadingIcon>
                    </div>
                </> : <>

                    {Object.keys(drawerAllPages).map((dp, k) => <DrawerList page={dp} key={k} elements={drawerAllPages[dp].slice(1)} primary={drawerAllPages[dp][0]} classes={classes} closeFn={() => drawerUpdate(false)} />)}

                </>}
                <div onClick={() => drawerUpdate(false)}>
                    <List>
                        <ListItem component={StyledLink} to="/guida">
                            <ListItemText>
                                <Typography type="body2" className={classes.listTypo}>
                                    <W i="page_guida" />
                                </Typography>
                            </ListItemText>
                        </ListItem>
                    </List>
                </div>


                <List className={classes.drawerFooter}>
                    <ListItem component={StyledLink} to="/clear-data">
                        <ListItemText>
                            <Typography type="body2" className={classes.listTypo}>
                                Clear data
                            </Typography>
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemText>
                            <Typography>Build {packageJson.version}</Typography>
                        </ListItemText>
                    </ListItem>
                </List>

            </Drawer>
        </>
    );
}


const StyledLink = styled(Link)`
    text-decoration: none !important;
`


const DrawerLogo = styled.img`
    width: 50%;
    margin: auto;
`
const LoadingIcon = styled(IconButton)`
	animation: rotate 1s infinite;

	@keyframes rotate {
		0% {
			transform: rotate(0deg);
		}

		33% {
			transform: rotate(180deg);
		}

		100% {
			transform: rotate(180deg);
		}
	}
`