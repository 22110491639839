import 'axios-progress-bar/dist/nprogress.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'shards-ui/dist/css/shards.min.css';
import './App.scss';

import { Redirect, Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { UserProvider, useUserContext, useUserContextUpdate } from './context/User/Provider';

import Bar from "./pages/Bar";
import { BottomBar } from './components/BottomBar/';
import Casa from './pages/Casa';
import { ClearData } from './pages/clearData';
import Credits from "./pages/credits";
import { DBConfig } from './idb/config';
import Guida from './pages/Guida';
import Header from './components/Header/';
import Home from './pages/Home';
import LangSwitcher from './pages/LangSwitcher';
import Login from './pages/Login';
import Notifications from "./components/Notifications";
import TestComponentAuth from './components/Auth/TestComponent';
import { ThemeProvider } from '@material-ui/core/styles';
import { initDB } from 'react-indexed-db';
import { loadProgressBar } from 'axios-progress-bar';
import { menuAllGet } from "./api/wp"
import theme from './theme';
import { useEffect } from 'react';

initDB(DBConfig);
loadProgressBar();

function Main() {
	const { assetsDownloaded, lang } = useUserContext();
	const { drawerAllPagesUpdate } = useUserContextUpdate();

	useEffect(() => {
		console.log("disable context menu");
		document.addEventListener('contextmenu', (e) => {
			e.preventDefault();
		});
	}, [])

	useEffect(() => {
		(async () => {
			const lang = localStorage.getItem("lang");
			// console.log("LANG", localStorage.getItem("lang"));
			if (!lang) return
			const menus = await menuAllGet({ lang });
			let menusOut = {};

			Object.keys(menus.results).forEach((key, pages) => {
				menusOut[key] = menus.results[key].map(e => {
					return {
						url: `/${key}/${e.object_id}`,
						title: e.title,
						image: e.thumbnail
					}
				})
			})

			console.log(menusOut);

			// const menusOut = Object.keys(menus.results).map((k) => {
			// 		const menu = menus.results[k];
			// 		return menu.map(m => {
			// 			m.url = `/${k}/${m.object_id}`;
			// 			return m;
			// 		});
			// 	})

			// 	console.log("MENUS", menusOut);

			// const menusOut = menus.results.slice(1).map(e => {
			// 	return {
			// 		url: `/bar/${e.object_id}`,
			// 		title: e.title,
			// 		image: e.thumbnail
			// 	}
			// })
			drawerAllPagesUpdate(menusOut)
		})()
	}, [lang])

	return (
		<Router>

			{/* <LocalStorageToContext /> */}


			<Header />
			{/* <Test /> */}

			<Switch>
				{/* <Route path="/page/:slug">
					<Page />
				</Route> */}

				<Route exact path="/">
					<Redirect to="/home" />
				</Route>

				<Route path="/home">
					{(!localStorage.getItem('lang') || localStorage.getItem('lang') === null) && <Redirect to="/lang-update" />}
					<Home />
				</Route>
				<Route path="/bar/" component={Bar} />
				<Route path="/casa" component={Casa} />
				<Route path="/guida" component={Guida} />
				<Route path="/login" component={Login} />

				<Route path="/credits" component={Credits} />
				<Route path="/lang-update" component={LangSwitcher} />
				<Route path="/clear-data" component={ClearData} />

				<Route path="/test">
					TEST
					<TestComponentAuth />
				</Route>


			</Switch>
			<Notifications />
			<BottomBar />


		</Router>
	);
}

export default function App() {
	return (
		<>
			<UserProvider>
				<ThemeProvider theme={theme}>
					<Main />
				</ThemeProvider>
			</UserProvider>
			{/* <BottomBar /> */}
		</>
	);
}
