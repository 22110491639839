import React, { useEffect } from 'react';
import { useUserContext, useUserContextUpdate } from '../context/User/Provider';

import { useIndexedDB } from 'react-indexed-db';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import langs from "../common/langs";

const LangSwitcher = () => {
	const history = useHistory();
	// CONTEXT
	const { lang, assetsDownloaded, currentPage } = useUserContext();
	const { langUpdate, assetsDownloadedUpdate, pointsUpdate, currentPageUpdate, showBottomBarUpdate, drawerPagesUpdate } = useUserContextUpdate();
	// IDB
	const DB_offlineData = useIndexedDB('offlineData');
	const DB_offlinePOI = useIndexedDB('offlinePOI');
	const DB_offlinePages = useIndexedDB('offlinePages');

	useEffect(() => {
		showBottomBarUpdate(false)
		drawerPagesUpdate(false)
	}, [])

	useEffect(() => {
		if (currentPage !== "langSwitch") currentPageUpdate("langSwitch")
	}, [currentPage])



	function langSwitch(lNew) {
		resetAll()
		console.group("lang switch")
		console.log(lang !== localStorage.getItem("lang"));
		console.log(lang);
		console.log(localStorage.getItem("lang"));
		console.groupEnd()
		// if (lang !== localStorage.getItem("lang")) {
		// 	DB_offlineData.clear().then(() => {
		// 		alert('DB_offlineData Clear!');
		// 	});
		// 	DB_offlinePOI.clear().then(() => {
		// 		alert('DB_offlineData Clear!');
		// 	});
		// }
		langUpdate(lNew)
		localStorage.setItem("lang", lNew)
		// history.push('/points-of-interest')
		history.push('/home')

	}

	function resetAll() {
		console.log("cleaning db");
		DB_offlineData.clear().then(() => {
			DB_offlinePOI.clear().then(() => {
				DB_offlinePages.clear().then(() => {
					assetsDownloadedUpdate(false)
					pointsUpdate(null)
					console.log("reset done");
					console.log('assetsDownloaded', assetsDownloaded)
				})
			})

		})
	}

	console.log("lang switcher");

	return (
		<Wrapper>
			<div>
				{/* {lang && <Redirect to="/" />} */}
				{/* <h2>Seleziona lingua</h2>
			<div>Corrente: {lang}</div>
			<button onClick={() => l('it')}>ITA</button> */}
				<div className="container">
					<div className="row">
						<div className="col-12 text-center">
							<h2>Sodade</h2>
							<h3>Casa da cultura</h3>
						</div>
					</div>
				</div>
				<div className="container">
					{/* <div className="row">
					<div className="col-12 text-center">
						<h2>Seleziona lingua</h2>
					</div>
				</div> */}
					<div className="row justify-content-center">
						{langs.map((l, k) => (
							<div className="col-12 text-center mb-4" key={k}>
								<Btn onClick={() => langSwitch(l.lang)}>
									<img src={l.flag} alt={l.lang} />
								</Btn>
							</div>
						))}
					</div>
				</div>
			</div>

		</Wrapper>
	);
};

const Wrapper = styled.div`
	width: 100%;
	min-height: 100vh;
	position: fixed;
	z-index: 1200;
	top: 0;
	left: 0;
	border: 1px solid;
	background-color: #EFEFEF;
	overflow-y: auto;
	max-height: 100%;
	
	> div {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		height: 100%;
		position: relative;
		min-height: 100vh;
		padding: 5vh 0;
	}

	h2 {
		/* margin-bottom: 10vh; */
		color: #55908b;
		margin-bottom: 0.3rem;
		text-shadow: 0 1px 3px rgba(8,6,6,0.06),0 1px 2px rgba(0,0,0,0.12);
		font-size: 3rem;
	}
	
	h3 {
		margin-bottom: 10vh;
		color: #777;
		font-size: 1.3rem;
		text-shadow: 0 1px 3px rgba(8,6,6,0.06),0 1px 2px rgba(0,0,0,0.12);
	}
`
const Btn = styled.button`

	width: 3rem;
	height: 3rem;
	box-shadow: 0 1px 3px rgba(8, 6, 6, 0.06), 0 1px 2px rgba(0, 0, 0, 0.12);
	border: 0px;
	padding: 0.5rem;
	background-color: #fff;
	border-radius: 50%;

	img {
		width: 100%;
		height: 100%;
	}

`

export default LangSwitcher;
