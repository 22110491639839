import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useUserContext, useUserContextUpdate } from '../../context/User/Provider';

import AssetsDownload from '../AssetsDownload';
import Page from "../Page"
// import LogRocket from 'logrocket';
import PointsOfInterest from '../points-of-interest';
import RedirectChecks from '../../common/RedirectChecks';
import { useAuth } from '../../components/Auth';
import { useIndexedDB } from 'react-indexed-db';

export default function Guida() {
    const { AuthenticatedRender } = useAuth()
    const { assetsDownloaded } = useUserContext();
    const { drawerPagesUpdate } = useUserContextUpdate();
    const DB_offlineMenu = useIndexedDB("offlineMenu")

    // useEffect(() => {
    //     if (!assetsDownloaded) return
    //     (async () => {
    //         const menuELems = await DB_offlineMenu.getAll()
    //         const menu = menuELems.filter(e => e.id === "guida")[0].elements.map(e => {
    //             return {
    //                 url: `/guida/${e.object_id}`,
    //                 title: e.title
    //             }
    //         })
    //         drawerPagesUpdate(menu);
    //     })()
    // }, [assetsDownloaded])

    return (
        <>
            {!assetsDownloaded && <RedirectChecks />}
            <Switch>
                <Route exact path="/guida">
                    <Redirect to="/guida/points-of-interest" />
                </Route>
                <Route path="/guida/assets-download">
                    <AssetsDownload fixed />
                </Route>
                <Route path="/guida/points-of-interest">
                    {/* {!assetsDownloaded && <Redirect to="/guida/assets-download" />} */}
                    {assetsDownloaded && <PointsOfInterest />}
                </Route>
                <Route path="/guida/:pageId">
                    <Page />
                </Route>
            </Switch>
            {/* <RedirectChecks /> */}
            {/* <W i="loading" />
						{localStorage.getItem('lang')} */}


        </>
    )
}