import './style.scss';

import { Badge, Button, Card, CardBody, CardFooter, CardHeader, CardImg, CardTitle } from 'shards-react';
import { Link, useHistory, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useUserContext, useUserContextUpdate } from '../../context/User/Provider';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ImageGallery from 'react-image-gallery';
import Loading from '../Loading';
import W from "../../locales"
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components"
import { useIndexedDB } from 'react-indexed-db';

const FileType = require('file-type');


const LoadPointsContext = () => {
	const { points } = useUserContext();
	const { pointsUpdate } = useUserContextUpdate();
	const DB_offlinePOI = useIndexedDB('offlinePOI');
	const [pointsSetted, setPointSetted] = useState(false);
	useEffect(() => {
		if (pointsSetted) return;
		DB_offlinePOI.getAll().then(
			(points) => {
				console.log('loading points', points);
				pointsUpdate(points);
				setPointSetted(true);
			},
			(error) => {
				console.log("DB_offlinePOI", error);
			}
		);
	}, [pointsSetted]);
	return null;
};

const PointFull = ({ }) => {
	let history = useHistory()
	// STATE
	const [featuredImage, setFeaturedImage] = useState();
	const [gallery, setGallery] = useState();
	// const [audio, setAudio] = useState();
	const [point, setPoint] = useState(false);
	// CONTEXT
	const { currentPage, changePage, points, audio } = useUserContext();
	const { currentPageUpdate, changePageUpdate, audioUpdate } = useUserContextUpdate();
	// IDB
	const { getByID, getAll } = useIndexedDB('offlineData');

	let { code } = useParams();

	// useEffect(() => {
	// 	if (featuredImage) return
	// 	let p = points?.find((p) => p.code == code);
	// 	if (!p?.featuredImage) return;
	// getByID(p?.featuredImage).then((dataFromDB) => {
	// 	console.log(dataFromDB);
	// 	setFeaturedImage(dataFromDB);
	// 	setPoint(p)
	// });
	// }, [featuredImage]);

	useEffect(() => {
		if (currentPage !== "poiSingle") currentPageUpdate("poiSingle")
	}, [currentPage])



	function gotoNumber(cod) {
		if (!cod) return
		changePageUpdate(true)
		audioUpdate(false)
		history.push(`/casa/points-of-interest/${cod}`);
	}


	useEffect(() => {
		window.scrollTo(0, 0)
		console.log("USEEFFECT set point");
		if (!points) return
		if (!changePage && point) return
		if (!changePage && featuredImage) return

		// console.log('points', points)
		let p = points?.find((p) => p.code == code);

		if (!p) {
			history.push(`/casa/points-of-interest/`);
			return
		}

		console.log("POINT", p);
		setPoint(p)
		if (p?.featuredImage) getByID(p?.featuredImage).then((dataFromDB) => {
			// console.log(dataFromDB);
			const mediaBlob = new Blob([dataFromDB.data]);
			const mediaObjectURL = window.URL.createObjectURL(mediaBlob);
			// let url = URL.createObjectURL(dataFromDB.data);
			// setFeaturedImage(url);
			setFeaturedImage(mediaObjectURL);
		});

		if(p?.audio) getByID(p?.audio).then((dataFromDB) => {
			if (!dataFromDB?.data) return false
			console.log(dataFromDB);
			window.datatest = dataFromDB.data
			// let url = URL.createObjectURL(dataFromDB.data);
			// audioUpdate(url)
			const mediaBlob = new Blob([dataFromDB.data], { type: 'audio/x-m4a' });
			const mediaObjectURL = window.URL.createObjectURL(mediaBlob);
			console.log('mediaObjectURL', mediaObjectURL)
			audioUpdate(mediaObjectURL)
		});

		if(p.gallery) getAll().then(async(dataFromDB) => {
			console.log("gallery dataFromDB", dataFromDB);
			var galleryElems = Object.keys(p.gallery).map(function(key) {
				return p.gallery[key].id;
			});
			
			const galleryFiltered = dataFromDB.filter(i => {
				return galleryElems.includes(i.id)
			})

			let galleryTemp = []

			// await galleryFiltered.forEach(async(i) => {
			// 	const mime = await FileType.fromBuffer(i.data)
			// 	const mediaBlob = await new Blob([i.data], { type: mime.mime });
			// 	const mediaObjectURL = window.URL.createObjectURL(mediaBlob);
			// 	galleryTemp.push(mediaObjectURL)
			// });

			// console.log("galleryTemp",galleryTemp);



			const propertyPhotoList = await Promise.all(
				galleryFiltered.map(async (i) => {
					const mime = await FileType.fromBuffer(i.data)
					const mediaBlob = await new Blob([i.data], { type: mime.mime });
					const mediaObjectURL = window.URL.createObjectURL(mediaBlob);
					return {
						original: mediaObjectURL,
						thumbnail: mediaObjectURL
					}
				})
			);

			setGallery(propertyPhotoList)
			console.log("GALLEY", gallery);


		})

		if (changePage) changePageUpdate(false)
	})



	// console.log(points?.find((p) => p.code == code));
	return point && (
		<>
			<div className="container">
				<div className="row">
					<div className="col-12 px-0 text-center mb-3">
						<img src={featuredImage} style={{ width: "100%" }} alt="" />
					</div>
					<div className="col-12 text-center">
						<h2 className="mb-5" dangerouslySetInnerHTML={{ __html: point.name}}></h2>
					</div>
				</div>
				<div className="row">
					
					<div className="col-12 mb-5">
						<Content>
							<div dangerouslySetInnerHTML={{ __html: point.content }} />
						</Content>
					</div>

				</div>


				{gallery && (
					<div className="row">
						<div className="col-12 mt-3 mb-5">
							{/* {gallery.map((g,i) => {
								return (
									<div key={i}>{g}</div>
								)
							})} */}
							<ImageGallery items={gallery} showFullscreenButton={false} showPlayButton={false} />
						</div>
					</div>
				)}

				{point.related && (
					<section>
						<div className="row">
							<div className="col-12">
								<h3><W i="poi_related" /></h3>
							</div>
						</div>
						<div className="row">
							{point.related.map(rel => {
								return (
									<div className="col-12">
										<RelLink onClick={() => gotoNumber(rel.cod)}>
											{rel.name}
										</RelLink>
									</div>
								)
							})}
						</div>
					</section>
				)}
				{}
			</div>
		</>
	);
};

const Content = styled.div`
	word-break: break-word;
	font-size: 1.15rem;
`

const RelLink = styled.button`
	color: #55908b;
	font-size: 1.15rem;
	padding: 1rem;
	background-color: #eaeaea;
	margin-bottom: 1rem;
	display: block;
	border: 0px;
	width:100%;

`

export { PointFull, LoadPointsContext };
