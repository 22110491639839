import axios from 'axios';

export const wpSettings = {
	apiUrl: 'https://sodadeboavista.com/wp-json/',
};

const poiGet = async ({ lang }) => {
	lang = lang === 'pt' ? 'pt-pt' : lang
	console.log('poiGet lang ', lang);
	const getUrl = wpSettings.apiUrl + 'frontend/v1/poi/get';
	// let res = await axios.get(getUrl, {
	// 	lang: 'it',
	// });
	// let { data } = res;

	const response = await fetch(wpSettings.apiUrl + `frontend/v1/poi/get?lang=${lang}`);
	const data = await response.json();
	console.log("FETCH RESPONSE", data);
	// this.setState({ totalReactPackages: data.total })
	//
	return data;
};

const pagesGet = async ({ lang }) => {
	lang = lang === 'pt' ? 'pt-pt' : lang
	console.group("pagesGet")
	console.log("lang", lang);
	// let res = await axios.get(getUrl, {
	// 	lang: 'it',
	// });
	// let { data } = res;

	const response = await fetch(wpSettings.apiUrl + `frontend/v1/pages/get?lang=${lang}`);
	const data = await response.json();
	console.log(data);
	console.groupEnd()
	// this.setState({ totalReactPackages: data.total })
	//
	return data;
};

const menuGet = async ({ lang, side }) => {
	lang = lang === 'pt' ? 'pt-pt' : lang
	console.group("menuGet", wpSettings.apiUrl + `frontend/v1/menu/get?lang=${lang}&side=${side}`)

	const response = await fetch(wpSettings.apiUrl + `frontend/v1/menu/get?lang=${lang}&side=${side}`);
	const data = await response.json();
	console.log(data);
	console.groupEnd()

	return data;
}

export const menuAllGet = async ({ lang }) => {
	lang = lang === 'pt' ? 'pt-pt' : lang
	console.group("menuAllGet", `lang? ${lang}`)
	console.log(wpSettings.apiUrl + `frontend/v1/menu/get-all?lang=${lang}`);
	const response = await fetch(wpSettings.apiUrl + `frontend/v1/menu/get-all?lang=${lang}`);
	const data = await response.json();
	console.log(data);
	console.groupEnd()
	
	return data;
}

const pagesGetContent = async ({ lang, id }) => {
	lang = lang === 'pt' ? 'pt-pt' : lang
	console.group("pagesGetContent")
	// let res = await axios.get(getUrl, {
	// 	lang: 'it',
	// });
	// let { data } = res;

	const response = await fetch(wpSettings.apiUrl + `wp/v2/pages/${id}?lang=${lang}`);
	const data = await response.json();
	console.log(data);
	console.groupEnd()
	return data;
};


export const authGet = async () => {
	// let res = await axios.get(getUrl, {
	// 	lang: 'it',
	// });
	// let { data } = res;

	const response = await fetch(wpSettings.apiUrl + `frontend/v1/auth/get`);
	const data = await response.json();
	console.groupEnd()
	return data;
};

export { poiGet, pagesGet, menuGet, pagesGetContent };
