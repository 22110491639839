import React, { useEffect } from 'react';
import { useUserContext, useUserContextUpdate } from '../context/User/Provider';

import { Link } from 'react-router-dom';
import styled from 'styled-components';
import homeTileAudioguida from "../assets/img/homeTileAudioguida.jpeg";
import homeTileCasa from "../assets/img/homeTileCasa.jpeg";
import homeTileSnacks from "../assets/img/homeTileSnacks.jpeg";
import { TileContainer } from '../common/styledElements';
import W from '../locales';

const Home = () => {
	const { points, assetsDownloaded, lang } = useUserContext();
	const { drawerPagesUpdate } = useUserContextUpdate()

	useEffect(() => {
		drawerPagesUpdate([])
	}, [])

	// const DB_offlineData = useIndexedDB('offlineData');
	// const DB_offlinePOI = useIndexedDB('offlinePOI');

	// function clear() {
	// 	DB_offlineData.clear().then(() => {
	// 		alert('DB_offlineData Clear!');
	// 	});
	// 	DB_offlinePOI.clear().then(() => {
	// 		alert('DB_offlinePOI Clear!');
	// 	});
	// }
	return (
		<Wrapper className="mt-3">
			{/* {!lang && <Redirect to="/lang-update" />}
			{lang && !assetsDownloaded && <Redirect to="/assets-download" />} */}


			<div className="container">
				{/* <div className="row">
					<div className="col-12">
						<button onClick={clear}>Clear</button>
					</div>
				</div> */}
				<div className="row">
					<div className="col-12 p-0">
						<Link to="/bar">
							<TileContainer img={homeTileSnacks}>
								<TileInner>
									<h2><W i="page_bar" /></h2>
								</TileInner>
							</TileContainer>
						</Link>
						<Link to="/casa">
							<TileContainer img={homeTileCasa}>
								{/* <TileInner className="black"> */}
								<TileInner>
									<h2><W i="page_casa" /></h2>
								</TileInner>
							</TileContainer>
						</Link>
						<Link to="/guida">
							<TileContainer img={homeTileAudioguida}>
								{/* <TileInner className="black"> */}
								<TileInner>
									<h2><W i="page_guida" /></h2>
								</TileInner>
							</TileContainer>
						</Link>
					</div>
				</div>
			</div>
		</Wrapper>
	);
};

const Wrapper = styled.div`
width:100%;

a {
	text-decoration: none;
}
`

export const TileInner = styled.div`
	width: 100%;
	height: 40vh;
	display: flex;
	justify-content: center;
	align-items: center;
	text-shadow: 0 3px 6px rgba(0,0,0,0.12), 0 3px 6px rgba(0,0,0,0.18);
	text-align: center;

	&:not(.black) h2 {
		color: #fff;
	}
`

export default Home;
