import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom';
import { useUserContext, useUserContextUpdate } from '../context/User/Provider';

import LoadingInline from '../components/LoadingInline';
import { pagesGetContent } from '../api/wp';
import styled from "styled-components"
import { useIndexedDB } from 'react-indexed-db';

const Page = ({ onDemand, staticId, children }) => {
    let { slug, pageId } = useParams();
    const location = useLocation();
    //// CONTEXT
    const { pages } = useUserContext();
    const { pagesUpdate } = useUserContextUpdate();
    //// STATE
    const [pagesSetted, setPagesSetted] = useState(false);
    const [page, setPage] = useState(false);
    const [featuredImage, setFeaturedImage] = useState();
    //// IDB
    const DB_offlinePages = useIndexedDB('offlinePages');
    const DB_offlineData = useIndexedDB('offlineData');


    // ON DEMAND
    useEffect(() => {
        if (!onDemand) return
        (async () => {
            setPage(false)
            console.log("PAGE LOAD");
            let queryId = staticId ? staticId : pageId
            console.log("pageId", queryId);
            const lang = localStorage.getItem('lang')
            let p = await pagesGetContent({ lang, id: queryId })
            console.log("PAGE CONTENT", p)
            setPage({
                name: p.title.rendered,
                content: p.content.rendered
            })
        })()
    }, [staticId, pageId])


    // CACHED
    useEffect(() => {
        if (onDemand) return

        DB_offlinePages.getAll().then(pages => {
            let p = pages.filter(pp => pp.id === Number(pageId))[0]
            setPage(p)

        })
    }, [staticId, pageId])

    useEffect(() => {
        if (!page) return
        if (!page.featuredImage) {
            setFeaturedImage(false)
            return
        }

        console.log("page feat");
        DB_offlineData.getByID(page?.featuredImage).then((dataFromDB) => {
            if (!dataFromDB) return;
            const mediaBlob = new Blob([dataFromDB.data]);
            const mediaObjectURL = window.URL.createObjectURL(mediaBlob);
            setFeaturedImage(mediaObjectURL);
        });

    }, [page])

    // useEffect(() => {
    //     if (onDemand) return
    //     if (pagesSetted) return;
    //     DB_offlinePages.getAll().then(
    //         (pages) => {
    //             console.log('loading pages', pages);
    //             pagesUpdate(pages);
    //             setPagesSetted(true);
    //         },
    //         (error) => {
    //             console.log(error);
    //         }
    //     );
    // }, [pagesSetted]);

    // useEffect(() => {
    //     if (onDemand) return
    //     console.log("USEEFFECT set page");
    //     if (!pages) return
    //     // if (point) return
    //     if (featuredImage) return

    //     // console.log('pages', pages)
    //     let p = pages?.find((p) => p.slug === slug);
    //     setPage(p)
    //     console.log("page", p);
    //     if (p?.featuredImage) DB_offlineData.getByID(p?.featuredImage).then((dataFromDB) => {
    //         // console.log(dataFromDB);
    //         if (!dataFromDB) return;
    //         const mediaBlob = new Blob([dataFromDB.data]);
    //         const mediaObjectURL = window.URL.createObjectURL(mediaBlob);
    //         // let url = URL.createObjectURL(dataFromDB.data);
    //         // setFeaturedImage(url);
    //         setFeaturedImage(mediaObjectURL);
    //     });
    // })






    return (
        <div className="container mt-5">
            {!page && <div className="row">
                <div className="col-12">
                    <LoadingInline />
                </div>
            </div>}
            {page && (
                <>
                    <div className="row">
                        <div className="col-12 text-center">
                            <h1 dangerouslySetInnerHTML={{ __html: page.name }}></h1>
                        </div>
                        {featuredImage && <div className="col-12 px-0 text-center mt-3">
                            <img src={featuredImage} style={{ width: "100%" }} alt="" />
                        </div>}
                        <div className="col-12 mt-5">
                            <Content dangerouslySetInnerHTML={{ __html: page.content }} />
                            {/* {page.content.split("\r\n").map(function(item, idx) {
                            return (
                                <div dangerouslySetInnerHTML={{ __html: item }} />
                            )
                        })} */}
                        </div>
                    </div>
                    {children}
                </>
            )}
        </div>
    )
}


const Content = styled.div`
    table {
        /* border: 1px solid; */
        margin-bottom: 2rem;
        td {
            border-bottom: 1px dotted #aaa;
        }
    }

    img {
        width: 100%;
        height: auto;
    }
`
export default Page
