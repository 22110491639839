import './style.scss';

import { useUserContext, useUserContextUpdate } from "../../context/User/Provider"

import AppBar from '@material-ui/core/AppBar';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Drawer from '../Drawer';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import styled from "styled-components"
import { useHistory } from 'react-router-dom';

// import packageJson from '../../package.alias.json';

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	menuButton: {
		marginRight: theme.spacing(2),
	},
	title: {
		flexGrow: 1,
		fontSize: "1.1rem"
	},
}));


const Header = () => {
	const { currentPage, drawerPages } = useUserContext()
	const { audioUpdate, drawerUpdate } = useUserContextUpdate()
	const classes = useStyles();
	const history = useHistory();

	function goBackFromPOISingle() {
		audioUpdate(false)
		history.push("/guida/points-of-interest")
	}

	return (
		<>
			<AppBar position="fixed">
				<Toolbar>
					{localStorage.getItem('lang') && <>
						{currentPage === "poiSingle" ? (
							<IconButton edge="start"
								className={classes.menuButton}
								color="inherit"
								aria-label="menu"
								onClick={goBackFromPOISingle}>
								<ArrowBackIcon />
							</IconButton>
						) : (
							<IconButton edge="start" onClick={() => drawerUpdate(true)} className={classes.menuButton} color="inherit" aria-label="menu">
								<MenuIcon />
							</IconButton>
						)}
					</>}
					<Typography variant="h6" className={classes.title} color="secondary">
						Sodade - Casa da Cultura
						{/* <Build>{packageJson.version}</Build> */}
						{/* {currentPage} */}
					</Typography>
					{/* <Button color="inherit">Login</Button> */}
				</Toolbar>
			</AppBar>
			<Drawer />
		</>
	);
};

export default Header;

const LoadingIcon = styled(IconButton)`
	animation: rotate 1s infinite;

	@keyframes rotate {
		0% {
			transform: rotate(0deg);
		}

		33% {
			transform: rotate(180deg);
		}

		100% {
			transform: rotate(180deg);
		}
	}
`