import React, { createContext, useContext, useState } from 'react';

import { useHistory } from 'react-router-dom';

const UserContext = createContext();
const UserContextUpdate = createContext();

export function useUserContext() {
	return useContext(UserContext);
}

export function useUserContextUpdate() {
	return useContext(UserContextUpdate);
}

function UserProvider({ children }) {


	const history = useHistory();
	const [lang, setLang] = useState(null);
	const [data, setData] = useState(null);
	const [points, setPoints] = useState(null);
	const [pages, setPages] = useState(null);
	const [drawer, setDrawer] = useState(null);
	const [audio, setAudio] = useState(null);
	const [currentPage, setCurrentPage] = useState(null);
	const [changePage, setChangePage] = useState(false);
	const [assetsDownloaded, setAssetsDownloaded] = useState(false);
	const [numpad, setNumpad] = useState(false)
	const [numpadNumber, setNumpadNumber] = useState(false)
	const [showBottomBar, setShowBottomBar] = useState(false)
	const [drawerPages, setDrawerPages] = useState(false)
	const [drawerAllPages, setDrawerAllPages] = useState(false)

	const context = {
		history,
		lang,
		assetsDownloaded,
		points,
		pages,
		data,
		audio,
		currentPage,
		changePage,
		drawer,
		numpad,
		numpadNumber,
		showBottomBar,
		drawerPages,
		drawerAllPages,
	};

	const contextUpdate = {
		langUpdate: (x) => setLang(x),
		assetsDownloadedUpdate: (x) => setAssetsDownloaded(x),
		pointsUpdate: (x) => setPoints(x),
		pagesUpdate: (x) => setPages(x),
		dataUpdate: (x) => setData(x),
		audioUpdate: (x) => setAudio(x),
		currentPageUpdate: (x) => setCurrentPage(x),
		changePageUpdate: (x) => setChangePage(x),
		drawerUpdate: (x) => setDrawer(x),
		numpadUpdate: (x) => setNumpad(x),
		numpadNumberUpdate: (x) => setNumpadNumber(x),
		showBottomBarUpdate: (x) => setShowBottomBar(x),
		drawerPagesUpdate: (x) => setDrawerPages(x),
		drawerAllPagesUpdate: (x) => setDrawerAllPages(x),
	};

	return (
		<UserContext.Provider value={context}>
			<UserContextUpdate.Provider value={contextUpdate}>{children}</UserContextUpdate.Provider>
		</UserContext.Provider>
	);
}

export { UserProvider, UserContext };
