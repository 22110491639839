import { useEffect, useState } from "react"

import { useAuth } from "."

const TestComponentAuth = () => {

    const { pass, authenticated, AuthenticatedRender } = useAuth()


    return <AuthenticatedRender> <div>
        <br /><br /><br />
        <h1>Test login component: logged in</h1>
        
    </div></AuthenticatedRender>

}


export default TestComponentAuth



