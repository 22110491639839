
import React from "react";

const L = {
    loading: {
        it: "Caricamento",
        en: "Loading",
        pt: "Carregando",
        de: "Wird geladen",
        fr: "Chargement",
        cs: "Načítání"
    },
    loading_description: {
        it: "La velocità di caricamento può dipendere dalla vostra connessione e dalle prestazioni del dispositivo.",
        en: "Download speed may depend on your connection and device performance.",
        pt: "A velocidade de download pode depender da conexão e do desempenho do dispositivo.",
        de: "Die Download-Geschwindigkeit kann von Ihrer Verbindung und der Geräteleistung abhängen.",
        fr: "La vitesse de téléchargement peut dépendre de votre connexion et des performances de votre appareil.",
        cs: "Rychlost načítání může záviset na vašem připojení a výkonu vašeho zařízení."
    },
    page_poiList: {
        it: "Punti di interesse",
        en: "Points of interest",
        pt: "Pontos de interesse",
        de: "Sehenswürdigkeiten",
        fr: "Points d’interêts",
        cs: "Zajímavosti"
    },
    page_langSwitch: {
        it: "Cambia lingua",
        en: "Switch language",
        pt: "Mudança de idioma",
        de: "Sprachwechsel",
        fr: "Changer de langue",
        cs: "Změna jazyka"
    },
    page_contacts: {
        it: "Contatti",
        en: "Contacts",
        pt: "Contatos",
        de: "Kontakte",
        fr: "Contacts",
        cs: "Kontakt"
    },
    page_credits: {
        it: "Credits",
        en: "Credits",
        pt: "Créditos",
        de: "Credits",
        fr: "Crédits",
        cs: "Kredity"
    },
    noti_updateMsg: {
        it: "Aggiornamento in corso...",
        en: "Updating...",
        pt: "Updating...",
        de: "Updating...",
        fr: "Updating...",
        cs: "Probíhá aktualizace..."
    },
    noti_updateBtn: {
        it: "Aggiorna",
        en: "Update",
        pt: "Update",
        de: "Update",
        fr: "Update",
        cs: "Aktualizace"
    },
    poi_related: {
        it: "Punti di interesse correlati",
        en: "Related points of interest",
        pt: "Pontos de interesse",
        de: "Sehenswürdigkeiten",
        fr: "Points d’interêts",
        cs: "Související zajímavosti"
    },

    page_bar: {
        it: "Café & Restaurant",
        en: "Cafe & Restaurant",
        pt: "Café & Restaurant",
        de: "Café & Restaurant",
        fr: "Café & Restaurant",
        cs: "Kavárna a restaurace"
    },

    page_casa: {
        it: "Casa della Memoria",
        en: "House of Memory",
        pt: "Casa da Memória",
        de: "Hause der Erinnerung",
        fr: "Maison de Mémoire",
        cs: "Dům paměti"
    },

    // TODO
    page_guida: {
        it: "Audioguida",
        en: "Audio guide",
        pt: "Guia de áudio",
        de: "Audioguide",
        fr: "Guide audio",
        cs: "Audioprůvodce"
    }

}

const W = ({ i }) => {
    // const { lang } = useUserContext();
    const lang = localStorage.getItem('lang')
    console.log("W i", i);
    if (i && lang && L[i][lang]) return <>{L[i][lang]}</>
    return <>{L[i]['en']}</>
}

export default W