export const DBConfig = {
	name: 'SodadeDB',
	version: 4,
	objectStoresMeta: [
		{
			store: 'offlineData',
			storeConfig: { keyPath: 'id', autoIncrement: false },
			storeSchema: [
				{ name: 'name', keypath: 'name', options: { unique: false } },
				{ name: 'type', keypath: 'type', options: { unique: false } },
			],
		},
		{
			store: 'offlinePOI',
			storeConfig: { keyPath: 'id', autoIncrement: false },
			storeSchema: [{ name: 'name', keypath: 'name', options: { unique: false } }],
		},
		{
			store: 'offlinePages',
			storeConfig: { keyPath: 'id', autoIncrement: false },
			storeSchema: [{ name: 'slug', keypath: 'slug', options: { unique: false } }],
		},
		{
			store: 'offlineMenu',
			storeConfig: { keyPath: 'id', autoIncrement: false },
			// storeSchema: [{ elements: 'array', options: { unique: false } }],
			storeSchema: [{ name: 'slug', keypath: 'slug', options: { unique: false } }],
		},
	],
};
